import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import useWindowSize from '@/hooks/useWindowSize';
import { isTablet } from 'react-device-detect';

import ImageComponent from '@/components/UI/image';
import CustomLink from '@/components/UI/link';

import frame from '@/assets/icons/Frame.png';
import downloadLogo from '@/assets/icons/customLogo.svg';
import redactor from '@/assets/icons/redactor.svg';
import mobileRedactor from '@/assets/icons/mobileRedactor.svg';

import style from './customization.module.scss';

const Customization = () => {
  const { windowWidth } = useWindowSize();
  const { t } = useTranslation();

  const renderIcon = useMemo(() => {
    if (windowWidth <= 720) {
      return mobileRedactor;
    }

    return redactor;
  }, [windowWidth]);

  return (
    <section
      className={classNames(style.block_custom, {
        [style.addPadding]: isTablet,
      })}
    >
      <div className={style.block_custom_head}>
        <h2 className={style.block_custom_title}>{t('costomization.title')}</h2>
        <CustomLink
          href='/registration'
          target={false}
          style={style.block_custom_button}
          text='button.want'
        />
      </div>
      <div className={style.wrapper}>
        <div className={style.wrapper_vertical}>
          <div
            className={classNames(style.block_custom_element, style.vertical)}
          >
            <h3 className={style.block_custom_element__title}>
              {t('costomization.verticalBlockTitle')}
            </h3>
            <ImageComponent
              image={frame}
              alt={'frame'}
              otherStyle={style.block_custom__frame}
            />
            <p className={style.block_custom_element__text}>
              {t('costomization.verticalBlockDescribe')}
            </p>
          </div>
        </div>
        <div className={style.wrapper_horisont}>
          <div
            className={classNames(style.block_custom_element, style.horisont)}
          >
            <ImageComponent
              image={downloadLogo}
              alt={'downloadLogo'}
              otherStyle={style.block_custom__downLoadLogo}
            />
            <div className={style.block_custom_element__content}>
              <h3
                className={classNames(
                  style.block_custom_element__title,
                  style.edge_title
                )}
              >
                {t('costomization.horizontaBlockDownLoadTitle')}
              </h3>
              <p className={style.block_custom_element__describe}>
                {t('costomization.horizontalBlockWownLoadDescribe')}
              </p>
            </div>
          </div>
          <div
            className={classNames(style.block_custom_element, style.horisont)}
          >
            <ImageComponent
              image={renderIcon}
              alt={'redactor'}
              otherStyle={style.block_custom__redactor}
            />
            <div className={style.block_custom_element__content}>
              <h3
                className={classNames(
                  style.block_custom_element__title,
                  style.edge_title
                )}
              >
                {t('costomization.horizontalBlockRedactorTitle')}
              </h3>
              <p className={style.block_custom_element__describe}>
                {t('costomization.horizontalBlockRedactorDescribe')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Customization;
